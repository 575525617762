/* eslint-disable no-param-reassign */
import { wait } from '@base/utils/wait';
import { axios } from '@/plugins';
import { snakeCase, isEmpty } from 'lodash';
import { registerOperation as registerOperationService } from '@envelope/services/setup';
import rubricActions from './rubric/actions';

const commitDraftData = ({ commit }, data) => {
  const linkSigner = data.links.lists || data.links.signers;

  commit('SET_DRAFT', data);
  commit('SET_LINKS', data.links);
  commit('signer/PATCH_LINKS', { linkSigner }, { root: true });
};

const checkArchivePreview = ({ getters, dispatch }) => {
  const archives = getters.getArchives;
  archives.forEach((archive) => {
    if (!archive.readyStamped) {
      dispatch('getPreviewArchive', archive);
    }
  });
};

const createDraft = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.post(url, payload);
    dispatch('commitDraftData', data.pack);
  } catch (err) {
    throw err;
  }
};

const fetchDraft = async ({ getters, commit, dispatch }) => {
  // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
  // TODO: Remove toggle
  const isNewEnvelopeSetup = getters.getToggles.newSetup && getters.getFromEnvelope;
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.get(url);
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle condition
    if (isNewEnvelopeSetup) {
      const oldArchives = getters.getArchives;
      if (oldArchives.length > 0) {
        const newArchives = data.pack.archives.filter((archive) =>
          oldArchives.some((a) => a.key === archive.key)
        );
        const newArchivesMapped = newArchives.map((item) => {
          const archiveFound = oldArchives.find((i) => i.key === item.key);
          return { ...item, stampKey: archiveFound.stampKey, orphan: archiveFound.orphan };
        });
        if (newArchivesMapped.length > 0) {
          newArchivesMapped.map((newArchive) => {
            commit('UPDATE_ARCHIVE', { ...newArchive, posted: true });
            return newArchive;
          });
        }
      } else {
        const newArchives = data.pack.archives.map((archive) => ({
          ...archive,
          posted: true,
        }));
        commit('SET_ARCHIVES', newArchives);
      }
    } else {
      commit('SET_ARCHIVES', data.pack.archives);
    }
    if (!getters.getFromEnvelope) {
      commit('SET_SIGNERS', data.pack.signers);
      commit('SET_GROUPS', data.pack.groups);
    }
    dispatch('commitDraftData', data.pack);
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove toggle condition
    if (isNewEnvelopeSetup) return;
    dispatch('checkArchivePreview');
  } catch (err) {
    throw err;
  }
};

const registerOperation = async ({ getters }, { name, data }) => {
  const links = getters.getLinks.setupSession;
  const jsonRootKey = 'setupSession';

  return registerOperationService({ links, name, data }, { jsonRootKey });
};

const addSignerOperation = async ({ dispatch, getters }, signer) => {
  const archives = getters.getArchives;
  const provideEvidences =
    signer.authentications?.length > 0 ? signer.authentications : signer.auths;
  const payload = {
    name: 'setup/add_signer',
    data: {
      signerKey: signer.editSignerKey,
      attributes: {
        email: signer.email,
        name: signer.name,
        communicateBy: signer.communicateBy,
        documentation: signer.documentation,
        birthday: signer.birthday,
        phoneNumber: signer.phoneNumber,
        hasDocumentation: signer.hasDocumentation,
        refusable: signer.features?.refusable,
        signAs: signer.newRoles,
        contact: signer.contact,
        group: signer.group,
      },
      requirements: {
        provideEvidences: provideEvidences?.map(snakeCase),
        roles: signer.newRoles,
      },
    },
  };

  const { operation } = await dispatch('registerOperation', payload);

  if (!isEmpty(archives) && !signer.editSignerKey) {
    await Promise.all(
      archives.map(async (archive) => {
        if (archive.url) {
          await dispatch('registerOperation', {
            name: 'setup/add_requirement',
            data: {
              documentKey: archive.key,
              signerKey: operation.data.signerKey,
            },
          });
        }
      })
    );
  }
};

const fetchSetupSession = async ({ getters, commit }) => {
  try {
    const links = getters.getLinks;

    if (links?.setupSession?.operations) return;

    const { data } = await axios.post(links.setupSession.self);

    commit('SET_LINKS', { ...links, setupSession: data.setupSession.links });
  } catch (err) {
    throw err;
  }
};

const addNewDocumentRequirements = async ({ dispatch, getters }, archive) => {
  await dispatch('fetchSigners');
  const groups = getters.getGroups;
  const signers = Object.values(groups).flat();
  await Promise.all(
    signers.map(async (signer) => {
      const payload = {
        name: 'setup/add_requirement',
        data: {
          signerKey: signer.key,
          documentKey: archive.key,
        },
      };

      await dispatch('registerOperation', payload);
    })
  );
  await dispatch('fetchSigners');
};

const postArchives = async ({ getters, commit, dispatch }, { formData, archive }) => {
  // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
  // TODO: Remove newSetup from condition
  const isNewEnvelopeSetup = getters.getToggles.newSetup && getters.getFromEnvelope;
  try {
    const url = getters.getLinks.archives;
    const { data } = await axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (e) => {
        const progress = Math.round((e.loaded * 100) / e.total);
        archive.progress = `${progress}`;
        archive.posted = true;
      },
    });
    const hasDraft = !_.isEmpty(getters.getDraft);
    if (hasDraft) {
      // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
      // TODO: Remove newSetup from condition
      if (isNewEnvelopeSetup) {
        commit('UPDATE_ARCHIVE', {
          ...data.archive,
          stampKey: archive.stampKey,
          posted: true,
          orphan: true,
        });
        await dispatch('addNewDocumentRequirements', data.archive);
        return data?.archive;
      }
      commit('REMOVE_UPLOADING_ARCHIVE', archive.key);
      commit('SET_NEW_ARCHIVE', data.archive);
      await dispatch('getPreviewArchive', data.archive);
      return data?.archive;
    }
    return data?.archive;
  } catch (err) {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove newSetup from condition
    if (isNewEnvelopeSetup) {
      commit('REMOVE_ARCHIVE', archive);
    } else {
      commit('REMOVE_UPLOADING_ARCHIVE', archive.key);
    }
    throw err;
  }
};

const deleteArchive = async ({ commit, dispatch }, payload) => {
  try {
    commit('SET_DELETING_ARCHIVE', payload);
    await axios.delete(payload.links.self);

    commit('REMOVE_DELETING_ARCHIVE', payload);
    commit('REMOVE_ARCHIVE', payload);
  } catch (error) {
    const status = error?.response?.status || 500;

    commit('REMOVE_DELETING_ARCHIVE', payload);

    if (status === 504) {
      await dispatch('deleteArchive', payload);
      return;
    }
    if (status === 404) {
      commit('REMOVE_ARCHIVE', payload);
      return;
    }

    throw error;
  }
};

const removeSigner = async ({ commit, dispatch }, payload) => {
  try {
    await axios.delete(payload.links.self);
    commit('REMOVE_SIGNER', payload);
    dispatch('fetchGroups');
  } catch (err) {
    throw err;
  }
};

const deleteSigner = async ({ dispatch, getters }, payload) => {
  try {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove newSetup from condition
    const isNewEnvelopeSetup = getters.getToggles.newSetup && getters.getFromEnvelope;

    if (isNewEnvelopeSetup) {
      const operation = {
        name: 'setup/remove_signer',
        data: { signer_key: payload.key },
      };
      await dispatch('registerOperation', operation);
    } else {
      await axios.delete(payload.links.self);
      dispatch('fetchSigners');
    }
  } catch (err) {
    throw err;
  }
};

// TOGGLE_ENVELOPE_SETUP_SCOPE
// TODO: Remove action function below
const updateSignerDocuments = async ({ commit }, { payload, signer }) => {
  try {
    const url = signer.links.requirements;

    commit('UPDATE_SIGNER_FROM_GROUP', signer);

    const response = await axios.patch(url, payload);

    commit('UPDATE_SIGNER_FROM_GROUP', response.data);
  } catch (err) {
    throw err;
  }
};

const patchDraftSettings = async ({ getters, dispatch }, payload) => {
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.patch(url, payload);
    if (data?.pack?.positionSignAbTest) {
      delete data.pack.positionSignAbTest;
    }

    dispatch('commitDraftData', data.pack);
    return [null, data.pack];
  } catch (err) {
    const packErrors = err.response?.data?.pack?.errors || err;
    return [packErrors, null];
  }
};

const requestSignature = async ({ getters }, payload) => {
  try {
    const url = getters.getLinks.finish;
    await axios.delete(url, { data: payload });
  } catch (err) {
    throw err;
  }
};

const checkArchivesNewSetupPreview = async ({ getters, dispatch, commit }) => {
  const archives = getters.getArchives;
  const hasPreviewNotReady = archives.some((archive) => !archive.readyStamped);
  if (archives.length > 0 && hasPreviewNotReady) {
    commit('envelopeSetup/SET_ALREADY_POOLING', true, { root: true });
    await dispatch('fetchDraft');
    await wait(3000);
    await dispatch('checkArchivesNewSetupPreview');
  }
  commit('envelopeSetup/SET_ALREADY_POOLING', false, { root: true });
};

const getPreviewArchive = async ({ getters, commit, dispatch }, archive) => {
  try {
    const hasArchives = _.get(getters, 'getDraft.archives');
    const alreadyDeletedArchive = _.get(getters, 'getAlreadyDeletedArchives').find(
      (a) => a?.key === archive?.key
    );
    if (alreadyDeletedArchive) {
      commit('REMOVE_ALREADY_DELETED_ARCHIVE', alreadyDeletedArchive);
      return;
    }
    const { data } = await axios.get(archive.links.self);

    if (data.archive.readyStamped) {
      commit('PATCH_ARCHIVE', { data: data.archive, key: archive.key });
    } else if (hasArchives) {
      await wait(3000);
      await dispatch('getPreviewArchive', data.archive);
    }
  } catch (err) {
    commit('REMOVE_ARCHIVE', archive);
    throw err;
  }
};

const fetchDrafts = async ({ getters, commit }, params) => {
  try {
    const url = getters.getLinks.drafts;
    const { data } = await axios.get(url, { params });
    commit('SET_DRAFTS', data.packs);
    commit('SET_PAGINATION', data.pagination);
  } catch (err) {
    throw err;
  }
};

const deleteDraft = async ({ getters, dispatch, commit }, payload) => {
  try {
    const url = getters.getLinks.drafts;
    const pagination = getters.getPagination;
    const drafts = getters.getDrafts;
    await axios.delete(url, { data: payload });

    if (drafts.length === 1 && pagination.page > 1) {
      pagination.page -= 1;
      commit('SET_PAGINATION', pagination);
    }

    dispatch('fetchDrafts', pagination);
  } catch (err) {
    throw err;
  }
};

const patchDraftFolder = async ({ getters, dispatch }, payload) => {
  try {
    const fromEnvelope = getters.getFromEnvelope;

    const url = fromEnvelope ? getters.getLinks.updateFolder : getters.getLinks.self;

    const { data } = await axios.patch(url, payload);
    dispatch('commitDraftData', data.pack);
  } catch (err) {
    throw err;
  }
};

const postUpdateOrdering = async ({ getters, dispatch, commit }, payload) => {
  try {
    const url = getters.getLinks.configureSequencing;
    const { data } = await axios.post(url, payload);
    dispatch('commitDraftData', data.pack);
    commit('SET_GROUPS', data.pack.groups);
  } catch (err) {
    throw err;
  }
};

const fetchSigners = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.signers;
    const { data } = await axios.get(url);
    const groups = data.groups || {};

    commit('SET_GROUPS', groups);
    commit('SET_SIGNERS', Object.values(groups).flat());
    commit('SET_SEQUENCE_ENABLED', Object.keys(groups).length > 1);
  } catch (err) {
    throw err;
  }
};

const updateGroups = async ({ getters, commit, dispatch }, payload) => {
  try {
    // TOGGLE_NEW_ENVELOPE_SETUP_ENABLED
    // TODO: Remove if condition
    const isNewEnvelopeSetup = getters.getToggles.newSetup && getters.getFromEnvelope;
    if (isNewEnvelopeSetup) {
      const operation = {
        name: 'setup/update_signer_group',
        data: { groups: payload.groups },
      };
      await dispatch('registerOperation', operation);
      await dispatch('fetchSigners');
    } else {
      const url = getters.getLinks.groups;
      const { data } = await axios.patch(url, payload);
      const groups = data.groups || {};

      commit('SET_GROUPS', groups);
      commit('SET_SIGNERS', Object.values(groups).flat());
      commit('SET_SEQUENCE_ENABLED', Object.keys(groups).length > 1);
    }
  } catch (err) {
    throw err;
  }
};

const fetchGroups = async ({ getters, commit }) => {
  try {
    const url = getters.getLinks.self;
    const { data } = await axios.get(url);
    commit('SET_GROUPS', data.pack.groups);
  } catch (err) {
    throw err;
  }
};

const getFolder = async ({ getters }) => {
  try {
    const url = getters.getLinks.folder;
    const { data } = await axios.get(url);
    return data;
  } catch (err) {
    throw err;
  }
};

const setSignatures = async ({ commit }, payload) => {
  commit('SET_SIGNATURES', payload);
};

const getWidgetLink = async ({ getters }) => {
  const { data } = await axios.get(getters.getLinks.selfSigner);
  return data.selfSignerUrl || '';
};

export default {
  commitDraftData,
  checkArchivePreview,
  createDraft,
  fetchDraft,
  postArchives,
  deleteArchive,
  removeSigner,
  deleteSigner,
  updateSignerDocuments,
  patchDraftSettings,
  requestSignature,
  getPreviewArchive,
  checkArchivesNewSetupPreview,
  fetchDrafts,
  deleteDraft,
  patchDraftFolder,
  postUpdateOrdering,
  fetchGroups,
  fetchSigners,
  updateGroups,
  getFolder,
  setSignatures,
  addNewDocumentRequirements,
  fetchSetupSession,
  registerOperation,
  addSignerOperation,
  getWidgetLink,
  ...rubricActions,
};
